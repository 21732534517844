body {
  overflow-y: scroll;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app-container {
  width: 100%;
  position: relative;
  height: 100vh;
}

img {
  width: 100%;
  height: auto;
}

::-webkit-scrollbar {
  position: fixed;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  position: fixed;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  position: fixed;
  -webkit-border-radius: 10px;
  border-radius: 0px;
  background: #c6273e;
  /* rgba(255, 0, 0, 0.8);*/
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
